<template>
  <div>
    <div class="page_header_title_box" v-if="isShow">
      <i class="el-icon-back" @click="handleCancel"></i>
      新增渠道设置
    </div>
    <div class="page_header_title_box" v-else>
      <i class="el-icon-back" @click="handleCancel"></i>编辑渠道设置
    </div>
    <div style="padding: 70px 20px 20px 20px">
      <div class="content_box">
        <el-form
          label-width="150px"
          :rules="rules"
          ref="formData"
          :model="formData"
        >
          <el-form-item label="渠道名称" prop="name">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.name"
              placeholder="请输入渠道名称"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="渠道编码" prop="code">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.code"
              placeholder="请输入渠道编码"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="适用分销商" prop="members">
            <el-button class="mini-search-btn" @click="distributorShow = true"
              >选择分销商</el-button
            >
            <div style="width: 550px">
              <el-table
                :data="formData.members"
                header-row-class-name="header-row"
                max-height="600"
                class="tableCenter"
              >
                <el-table-column
                  align="center"
                  label="分销商ID"
                  width="120"
                  prop="distributorId"
                ></el-table-column>
                <el-table-column
                  align="center"
                  label="分销商名称"
                  width="320"
                  prop="distributorName"
                ></el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      style="margin-top: 0px; margin-bottom: 0px"
                      class="mini-delete-btn"
                      @click="handleDeleteNoDistributor(scope.$index)"
                    >
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </el-form>
        <div class="clearfix footbtn">
          <el-button
            type="primary"
            :loading="dloading"
            style="margin-left: 46.5%"
            @click="handleSubmit()"
            >保存</el-button
          >
          <el-button @click="handleCancel()">返回</el-button>
        </div>
      </div>
      <el-dialog
        :modal-append-to-body="false"
        :visible="distributorShow"
        :before-close="disCancel"
        width="80%"
      >
        <selectDistributor
          :distributorData="distributorData"
          ref="selectDistributor"
          @cancel="cancel"
          @submit="disSubmit"
        >
        </selectDistributor>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import selectDistributor from "@/components/distributorList";
import api from "@/api/allUrl";
export default {
  name: "channelDetails",
  components: {
    selectDistributor,
  },
  data() {
    return {
      dloading: false,
      distributorData: [],
      distributorShow: false,
      isShow: true,
      formData: {
        code: "",
        name: "",
        distributorIds: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入渠道名称",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入渠道编码",
            trigger: "blur",
          },
        ],
        // members: [{
        //   required: true,
        //   message: '请选择适用分销商',
        //   trigger: 'blur'
        // }]
      },
    };
  },
  activated() {
    this.distributorData = [];
    if (this.$route.query.checkMsg == 3) {
      this.isShow = false;
      this.getChannelDetails();
    } else {
      this.isShow = true;
    }
  },
  methods: {
    handleDeleteNoDistributor(index) {
      this.formData.members.splice(index, 1);
    },
    disSubmit(msg) {
      console.log("分销商数据：", msg);
      // this.formData.members = [];
      var obj = {};
      msg.forEach((item) => {
        if (item.companyName == undefined) {
          obj = {
            channelId: item.channelId,
            distributorId: item.distributorId,
            distributorName: item.distributorName,
            id: item.id,
          };
        } else {
          obj = {
            channelId: "",
            distributorId: item.id,
            distributorName: item.name,
            id: item.id,
          };
        }
        this.formData.members.push(obj);
      });

      this.cancel();
      // this.$refs.selectDistributor.handleCancel()
    },
    cancel() {
      this.distributorShow = false;
    },
    disCancel() {
      this.$refs.selectDistributor.handleCancel();
    },
    handleDeleteDistributor(index) {
      this.formData.members.splice(index, 1);
    },
    // 必填验证
    handleSubmit() {
      this.dloading = true;
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          if (this.isShow) {
            this.$api
              .post(this, api.addChannelDetails, this.formData)
              .then((res) => {
                if (res.success) {
                  this.$message.success({
                    message: "新增渠道配置成功",
                    duration: 3 * 1000,
                  });
                  this.handleCancel();
                }
                this.dloading = false;
              });
          } else {
            this.updateDevicetypedetails();
          }
        } else {
          this.$message({
            message: "请先将设备信息补充完整",
            type: "error",
            duration: 3 * 1000,
            onClose: () => {},
          });
          this.dloading = false;
        }
      });
    },
    updateDevicetypedetails() {
      this.formData.id = this.$route.query.id;
      this.$api
        .post(this, api.updateChannelDetails, this.formData)
        .then((res) => {
          if (res.success) {
            this.$message.success({
              message: "修改成功",
              duration: 3 * 1000,
            });
            this.handleCancel();
          }
          this.dloading = false;
        });
    },
    getChannelDetails() {
      this.$api
        .get(this, api.getChannelDetails, {
          id: this.$route.query.id,
        })
        .then((res) => {
          this.formData = res.data;
        });
    },
    handleCancel() {
      // 返回操作
      this.$router.go(-1);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>

<template>
  <div>
    <div class="page_header_title_box">
      <i class="el-icon-back" @click="handleCancel"></i>
      订单详情
    </div>
    <div style="padding: 70px 20px 20px 20px">
      <div class="content_box">
        <div style="margin-top: 10px; margin-bottom: 20px; text-align: center">
          基本信息
        </div>
        <div class="order_box">
          <div class="order_item_box">
            <el-form label-width="150px">
              <el-form-item label="订单号:">
                {{ orderDetail.orderCode }}
              </el-form-item>
              <el-form-item label="支付方式:"> 微信支付 </el-form-item>
              <el-form-item label="支付单号:">
                {{ orderDetail.outTradeNo }}
              </el-form-item>
              <el-form-item label="下单时间:">
                {{ orderDetail.createTime }}
              </el-form-item>
            </el-form>
          </div>
          <div class="order_item_box">
            <el-form label-width="150px">
              <el-form-item label="订单类型:"> 定制订单 </el-form-item>
              <el-form-item label="订单状态:">
                {{ orderStatus(orderDetail.orderStatus) }}</el-form-item
              >
              <el-form-item label="配送方式:">
                {{
                  orderDetail.logisticsRes != null
                    ? logisticsRes(orderDetail.logisticsRes.shipperCode)
                    : ""
                }}
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div style="margin-top: 40px; margin-bottom: 20px; text-align: center">
          收货人信息
        </div>
        <div class="order_box">
          <div class="order_item_box">
            <el-form label-width="150px">
              <el-form-item label="收货人:">
                {{ orderDetail.userAddress.userName }}
              </el-form-item>
              <el-form-item label="地址:">
                {{ orderDetail.userAddress.countryName }}
                {{ orderDetail.userAddress.provinceName }}
                {{ orderDetail.userAddress.cityName }}
                {{ orderDetail.userAddress.districtName }}
                {{ orderDetail.userAddress.address }}
              </el-form-item>
            </el-form>
          </div>
          <div class="order_item_box">
            <el-form label-width="150px">
              <el-form-item label="邮编:"> - </el-form-item>
              <el-form-item label="手机:">
                {{ orderDetail.userAddress.phone }}
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div style="margin-top: 40px; margin-bottom: 20px; text-align: center">
          商品信息
        </div>
        <el-table :data="orderDetail.orderItemList">
          <el-table-column
            label="id"
            align="center"
            prop="id"
          ></el-table-column>
          <el-table-column
            label="商品名称"
            align="center"
            prop="name"
          ></el-table-column>
          <el-table-column
            label="材质"
            align="center"
            prop="materialName"
          ></el-table-column>
          <el-table-column
            label="型号"
            align="center"
            prop="modelName"
          ></el-table-column>
          <el-table-column
            label="价格"
            align="center"
            prop="price"
          ></el-table-column>
          <el-table-column label="图片" align="center">
            <template slot-scope="scope">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.image"
                fit="contain"
                :preview-src-list="[scope.row.image]"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column label="生产图片" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                v-if="scope.row.generateImage != '不打印'"
                @click="downImage(scope.row.generateImage)"
                >下载</el-button
              >
              <div v-else>不打印</div>
            </template>
          </el-table-column>
        </el-table>
        <div
          style="
            margin-top: 20px;
            margin-bottom: 20px;
            text-align: right;
            color: #d10000;
          "
        >
          <span style="color: rgb(93 93 93)">订单总金额：</span>￥{{
            orderDetail.totalAmount
          }}<span style="color: rgb(93 93 93)">，优惠金额：</span>￥{{
            orderDetail.reductionAmount || 0
          }}
        </div>
        <div class="clearfix">
          <el-button @click="handleCancel()">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../../api/allUrl";
import axios from "axios";
export default {
  name: "diyOrderdetails",
  data() {
    return {
      orderDetail: {},
    };
  },
  activated() {
    this.getOrderDetails();
  },
  methods: {
    logisticsRes(val) {
      console.log(val);
      switch (val) {
        case "EMS":
          return "邮政快递";
        case "ZTO":
          return "中通快递";
      }
    },
    // 订单状态
    orderStatus(val) {
      switch (val) {
        case 1:
          return "待付款";
        case 2:
          return "待发货";
        case 3:
          return "待收货";
        case 4:
          return "已关闭";
        case 5:
          return "已完成";
        case 6:
          return "待审核";
        case 7:
          return "已拒绝";
      }
    },
    getOrderDetails() {
      const service = axios.create({
        baseURL: "/samsung_api",
        timeout: 60000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Platform: "GF60004",
          Version: "1.0.0",
          token: "",
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          tenantNo: 100,
          value: 100,
        },
        withCredentials: false,
      });
      service({
        method: "get",
        url:
          "https://api.jakit.shop" +
          api.getDiyOrderDetails +
          "/" +
          this.$route.query.id,
        header: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        this.orderDetail = res.data.data;
      });
      // this.$api.get(this, api.getDiyOrderDetails + '/' + this.$route.query.id).then((res) => {
      //   if (res.success) {
      //     this.orderDetail = res.data;
      //   }
      // });
    },
    handleCancel() {
      // 返回操作
      this.$router.go(-1);
    },
    // 下载生产图片
    downImage(row) {
      let url = row;
      if (url) {
        if (
          url.indexOf(".png") > -1 ||
          url.indexOf(".jpeg") > -1 ||
          url.indexOf(".jpg") > -1
        ) {
          this.handledownImg(url, "生产图");
        } else {
          window.open(url);
        }
      } else {
        this.$message({
          type: "info",
          message: "生产图信息错误！",
        });
      }
    },
    // 保存图片
    handledownImg(imgsrc, name) {
      var userAgent = navigator.userAgent; // 取得浏览器的 userAgent 字符串
      var isOpera = userAgent.indexOf("Opera") > -1;
      let image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL("image/png"); // 得到图片的 base64 编码数据
        if (
          userAgent.indexOf("Trident") > -1 ||
          (userAgent.indexOf("compatible") > -1 &&
            userAgent.indexOf("MSIE") > -1 &&
            !isOpera)
        ) {
          // 判断是否 Edge/IE 浏览器
          var bstr = atob(url.split(",")[1]);
          var n = bstr.length;
          var u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          // 创建blob对象
          var blob = new Blob([u8arr]);
          // 调用浏览器的方法，调起 IE 的下载流程
          window.navigator.msSaveOrOpenBlob(blob, name + ".png");
        } else {
          let a = document.createElement("a"); // 生成一个 a 元素
          let event = new MouseEvent("click"); // 创建一个单击事件
          a.download = name || "photo"; // 设置图片名称
          a.href = imgsrc; // 将图片 URL 设置为 a.href 属性
          a.dispatchEvent(event); // 触发 a 的单击事件
        }
      };
      image.src = imgsrc;
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.clearfix {
  text-align: center;
  margin-top: 30px;
}
.order_box {
  display: flex;
  align-items: center;
  justify-content: center;
  .order_item_box {
    text-align: left;
    width: 500px;
    margin-left: 100px;
  }
}
</style>

<template>
  <div>
    <div class="page_header_title_box">第三方映射分类列表</div>
    <div style="padding: 70px 20px 20px 20px">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">
            <el-button class="mini_add_btn" @click="addpuls(1)"
              >+ 新增</el-button
            >
          </div>
          <div class="search_for_box"></div>
        </div>
        <!-- <el-table :data="tableData" header-row-class-name="header-row" class="tableCenter" v-loading="loading">
          <el-table-column label="第三方商品品类" align="center" prop="commodityCategory"></el-table-column>
          <el-table-column label="第三方商品品类编码" align="center" prop="commodityCategoryCode"></el-table-column>
          <el-table-column label="第三方商品分类" align="center" prop="commodityClassify"></el-table-column>
          <el-table-column label="第三方商品分类编码" align="center" prop="commodityClassifyCode"></el-table-column>
          <el-table-column label="第三方商品SPU编码" align="center" prop="commodityCode"></el-table-column>
          <el-table-column label="第三方商品商品SPU名" align="center" prop="commodityName"></el-table-column>
          <el-table-column label="商品类型id" align="center" prop="factoryModelId"></el-table-column>
          <el-table-column label="商品类型名称" align="center" prop="factoryModelName"></el-table-column>
          <el-table-column label="操作" width="200" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button @click="addpuls(3, scope.row.id)">编辑</el-button>
              <el-button type="danger" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table> -->
        <el-table
          :data="tableData"
          header-row-class-name="header-row"
          class="tableCenter"
          v-loading="loading"
        >
          <el-table-column
            label="第三方商品品类"
            align="center"
            prop="commodityCategory"
          ></el-table-column>
          <el-table-column
            label="第三方商品品类编码"
            align="center"
            prop="commodityCategoryCode"
          ></el-table-column>
          <el-table-column
            label="第三方商品分类"
            align="center"
            prop="commodityClassify"
          ></el-table-column>
          <el-table-column
            label="第三方商品分类编码"
            align="center"
            prop="commodityClassifyCode"
          ></el-table-column>
          <el-table-column
            label="第三方商品SPU编码"
            align="center"
            prop="commodityCode"
          ></el-table-column>
          <el-table-column
            label="第三方商品商品SPU名"
            align="center"
            prop="commodityName"
          ></el-table-column>
          <el-table-column
            label="商品类型id"
            align="center"
            prop="factoryModelId"
          ></el-table-column>
          <el-table-column
            label="商品类型名称"
            align="center"
            prop="factoryModelName"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="200"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button type="primary" @click="addpuls(3, scope.row.id)"
                >编辑</el-button
              >
              <el-button type="danger" @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :total="total"
          :current-page="pageInfo.page"
          @size-change="onSizeCHange"
          @current-change="onCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/allUrl";
export default {
  name: "mappingType",
  data() {
    return {
      pageInfo: {
        page: 1,
        size: 10,
      },
      tableData: [],
      total: 1,
      loading: false,
    };
  },
  activated() {
    this.loading = true;
    this.getRelevanceList();
  },
  methods: {
    addpuls(checkMsg, id) {
      var query = {};
      if (id) {
        query = {
          checkMsg: checkMsg,
          id: id,
        };
      } else {
        query = {
          checkMsg: checkMsg,
        };
      }
      this.$router.push({
        name: "mapping_details",
        query: query,
      });
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该映射分类，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then((res) => {
          console.log(res);
          this.$api
            .delete(this, api.deleteRelevanceList, { id: row.id })
            .then((res) => {
              if (res.success) {
                this.$message.success({
                  message: "删除成功",
                  duration: 3 * 1000,
                });
                this.pageInfo.page = 1;
                this.loading = true;
                this.getRelevanceList();
              }
            });
        })
        .catch((res) => {
          console.log(res);
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getRelevanceList() {
      this.$api.get(this, api.getRelevanceList, this.pageInfo).then((res) => {
        if (res.success) {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
    onSizeCHange(val) {
      // 分页方法
      this.loading = true;
      this.pageInfo.size = val;
      this.pageInfo.page = 1;
      this.getRelevanceList();
    },
    onCurrentChange(val) {
      // 分页方法
      this.loading = true;
      this.pageInfo.page = val;
      this.getRelevanceList();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>

<template>
  <div>
    <div class="page_header_title_box">渠道设置</div>
    <div style="padding: 70px 20px 20px 20px">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">
            <el-button class="mini_add_btn" @click="handlePlatform(1)"
              >新增</el-button
            >
          </div>
          <div class="search_for_box">
            <el-input
              placeholder="请输入渠道名称或编码"
              v-model="pageInfo.content"
              @keyup.enter.native="onSearch()"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click.prevent="onSearch()"
              ></el-button>
            </el-input>
          </div>
        </div>
        <el-table :data="channelList" v-loading="loading">
          <el-table-column
            label="渠道名称"
            align="center"
            prop="name"
          ></el-table-column>
          <el-table-column
            label="渠道编码"
            align="center"
            prop="code"
          ></el-table-column>
          <el-table-column label="关联分销商" align="center">
            <template slot-scope="scope">
              {{ membersName(scope.row.members) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="warning" @click="handlePlatform(3, scope.row.id)"
                >编辑
              </el-button>
              <el-button type="danger" @click="handleDelete(scope.row)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :total="total"
          :current-page="pageInfo.page"
          @size-change="onSizeCHange"
          @current-change="onCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/allUrl";
export default {
  name: "channelConfiguration",
  data() {
    return {
      pageInfo: {
        content: "",
        page: 1,
        size: 10,
      },
      channelList: [],
      total: 1,
      loading: false,
    };
  },
  activated() {
    this.loading = true;
    this.getChannelList();
  },
  methods: {
    handleDelete(row) {
      // 删除按钮
      this.$confirm("此操作将永久删除该渠道配置，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then((res) => {
          console.log(res);
          this.$api
            .delete(this, api.deleteChannelDetails, { id: row.id })
            .then((res) => {
              if (res.success) {
                this.$message.success({
                  message: "删除成功",
                  duration: 3 * 1000,
                });
                this.pageInfo.page = 1;
                this.pageInfo.content = "";
                this.loading = true;
                this.getChannelList();
              }
            });
        })
        .catch((res) => {
          console.log(res);
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 添加/查看/编辑 - （1，添加，3，查看/编辑）
    handlePlatform(checkMsg, id) {
      var query = {};
      if (id) {
        query = {
          checkMsg: checkMsg,
          id: id,
        };
      } else {
        query = {
          checkMsg: checkMsg,
        };
      }
      this.$router.push({
        name: "channelDetails",
        query: query,
      });
    },
    membersName(data) {
      var name = "";
      if (data != undefined) {
        data.forEach((item) => {
          name += item.distributorName + "、";
        });
      } else {
        name = "-";
      }

      return name;
    },
    getChannelList() {
      this.$api.get(this, api.getChannelList, this.pageInfo).then((res) => {
        this.channelList = res.data.list;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    onSizeCHange(val) {
      // 分页方法
      this.loading = true;
      this.pageInfo.size = val;
      this.pageInfo.page = 1;
      this.getChannelList();
    },
    onCurrentChange(val) {
      // 分页方法
      this.loading = true;
      this.pageInfo.page = val;
      this.getChannelList();
    },
    onSearch() {
      this.loading = true;
      this.pageInfo.page = 1;
      this.getChannelList();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>

<template>
  <div class="goods_box">
    <div class="nav-bar">
      <el-select
        style="width: 120px"
        size="mini"
        v-model="pageInfo.saleStatus"
        clearable
        placeholder="选择状态"
      >
        <el-option
          v-for="item in saleStatusList"
          :key="item.saleStatus"
          :value="item.saleStatus"
          :label="item.saleStatusName"
        ></el-option>
      </el-select>
      <el-button
        class="mini-search-btn"
        size="mini"
        @click="filter()"
        style="float: right; margin-left: 5px"
        >搜索</el-button
      >
      <el-input
        size="mini"
        style="float: right; width: 250px"
        placeholder="货品名称/货品编号/货品条形码"
        v-model.trim="pageInfo.content"
      ></el-input>
    </div>

    <div>
      <el-table
        ref="multipleSelect"
        :data="tableData"
        tooltip-effect="dark"
        @select="select"
        @select-all="selectAll"
        header-row-class-name="header-row"
        @selection-change="handleSelectionChange"
        :class="{ 'select-all': conFlag === 'exchangeActivity' }"
      >
        <el-table-column
          align="center"
          type="selection"
          :selectable="checkboxT"
          width="55"
        ></el-table-column>
        <el-table-column
          align="center"
          label="商品编号"
          prop="goodsNo"
        ></el-table-column>
        <el-table-column
          align="center"
          label="商品名称"
          show-overflow-tooltip
          prop="goodsName"
        ></el-table-column>
        <el-table-column
          align="center"
          label="货号"
          prop="itemCode"
        ></el-table-column>
        <el-table-column
          align="center"
          label="货品名称"
          show-overflow-tooltip
          prop="itemName"
        ></el-table-column>
        <el-table-column
          align="center"
          label="上架状态"
          prop="saleStatus"
          :formatter="formatStatus"
        ></el-table-column>
      </el-table>
      <el-pagination
        :total="total"
        :current-page="pageInfo.page"
        @@size-change="sizeChange"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <div class="foot-btn">
      <el-button
        class="mini-search-btn"
        style="margin-left: 47%"
        @click="handleSubmit()"
        >确定</el-button
      >
      <el-button size="mini" @click="handleCancel">返回</el-button>
    </div>
  </div>
</template>
<script>
import api from "@/api/allUrl";
export default {
  props: [
    "selectItemsData",
    "goodsType",
    "conFlag",
    "relevanceMaterialFlag",
    "multipleChoice",
  ],
  data() {
    return {
      categoryId: "",
      brandId: "",
      categorylist: [],
      brandlist: [],
      pageInfo: {
        page: 1,
        size: 10,
        content: "",
        goodsType: this.goodsType,
        relevanceMaterialFlag: this.relevanceMaterialFlag, // 是否关联材质 1-是 2-否
      },
      tableData: [],
      total: 0,
      loading: false,
      multipleSelect: [],
      selected: [],
      isSelect: false,
      saleStatusList: [
        { saleStatus: 3, saleStatusName: "已上架" },
        { saleStatus: 1, saleStatusName: "已下架" },
        { saleStatus: 2, saleStatusName: "审批中" },
      ],
    };
  },
  activated() {
    this.multipleSelect = [];
    if (this.selectItemsData !== undefined && this.selectItemsData.length > 0) {
      this.multipleSelect = this.multipleSelect.concat(this.selectItemsData); // 第一次进入时
    }
    this.dataForamt();
  },
  methods: {
    selectRow() {
      // 商品请求数据变化时，重新选择行（如，删除、数据变化）
      this.$refs.multipleSelect.clearSelection();
      this.multipleSelect.forEach((row1) => {
        this.tableData.forEach((row2) => {
          if (row1.itemCode === row2.itemCode) {
            this.$refs.multipleSelect.toggleRowSelection(row2);
            if (row1.id != null) {
              this.$set(row2, "status", 1);
            }
          }
        });
      });
    },
    dataForamt() {
      this.$api.get(this, api.getGoodsItemPoList, this.pageInfo).then((res) => {
        if (res.success) {
          this.isSelect = false;
          this.total = res.data.total;
          this.multipleSelect.forEach((row1) => {
            // 重新获取数据时，判断哪些选中了
            res.data.list.forEach((row2) => {
              if (row1.itemCode === row2.itemCode) {
                this.$refs.multipleSelect.toggleRowSelection(row2);
                this.selected.push(row2);
                if (row1.id != null) {
                  this.$set(row2, "status", 1);
                }
              }
            });
          });
          this.tableData = res.data.list;
        }
      });
    },
    select(selection, row) {
      this.isSelect = true;
      //判断是否要多选
      if (this.multipleChoice != true) {
        let d = false;
        for (let i = 0; i < this.multipleSelect.length; i++) {
          if (this.multipleSelect[i].id === row.id) {
            this.multipleSelect.splice(i, 1);
            d = true;
            break;
          }
        }
        if (!d) {
          this.multipleSelect.push(row);
          this.multipleSelect = this.setArr(this.multipleSelect);
        }
      } else {
        this.$refs.multipleSelect.clearSelection();
        this.multipleSelect = [row];
        this.multipleSelect = this.setArr(this.multipleSelect);
        this.$refs.multipleSelect.toggleRowSelection(row);
      }
    },
    selectAll(selection) {
      //判断是否要多选
      if (this.multipleChoice != true) {
        this.isSelect = true;
        if (selection.length === 0) {
          this.tableData.forEach((row) => {
            for (let i = 0; i < this.multipleSelect.length; i++) {
              if (this.multipleSelect[i].id === row.id) {
                this.multipleSelect.splice(i, 1);
                break;
              }
            }
          });
        } else {
          this.multipleSelect = this.setArr(
            this.multipleSelect.concat(selection)
          );
        }
      } else {
        this.$refs.multipleSelect.clearSelection();
        this.$refs.multipleSelect.toggleRowSelection(this.multipleSelect[0]);
      }
    },
    setArr(arr) {
      // 去重
      const obj = {};
      const temp = [];
      for (let i = 0; i < arr.length; i++) {
        const type = Object.prototype.toString.call(arr[i].itemCode); // 不加类型 分不清 1 '1'
        if (!obj[arr[i].itemCode + type]) {
          temp.push(arr[i]);
          obj[arr[i].itemCode + type] = true; // 这里给true 利于代码阅读和判断。  如果给 0,'' ,false ,undefined 都会在if那里判断为 false 不利于代码阅读
        }
      }
      return temp;
    },

    sizeChange(size) {
      // 分页页数
      this.pageInfo.size = size;
      this.pageInfo.page = 1;
      this.dataForamt();
    },

    currentChange(page) {
      // 分页总数
      this.pageInfo.page = page;
      this.dataForamt();
    },

    checkboxT() {
      return true;
    },

    handleSelectionChange(val) {
      // 当切换页面时的作用
      if (
        val.length === 0 &&
        this.multipleSelect.length !== 0 &&
        !this.isSelect
      ) {
        this.multipleSelect.forEach((row1) => {
          this.tableData.forEach((row2) => {
            if (row1.itemCode === row2.itemCode) {
              this.$refs.multipleSelect.toggleRowSelection(row2);
            }
          });
        });
        this.isSelect = true;
      }
    },

    formatStatus(row, col, val) {
      // 上架状态值
      switch (val) {
        case 1:
          return "已下架";
        case 2:
          return "审批中";
        case 3:
          return "已上架";
      }
    },

    filter() {
      // 搜索操作
      this.dataForamt();
    },

    handleSubmit() {
      console.log(this.multipleSelect);
      this.multipleSelect.forEach((item) => {
        if (item.id) {
          this.$set(item, "itemId", item.id);
        }
      });
      // 确定操作，给父页面监听
      this.$emit("submit", this.multipleSelect);
    },

    handleCancel() {
      // 返回操作，给父页面监听
      this.$emit("cancel");
    },
  },
  watch: {
    selectItemsData: {
      // 请求的分销商数据变化时
      handler() {
        this.multipleSelect = [];
        this.multipleSelect = this.multipleSelect.concat(this.selectItemsData);
        this.multipleSelect = this.setArr(this.multipleSelect);
        this.selectRow();
      },
      deep: true,
    },
    "pageInfo.saleStatus": {
      handler() {
        this.pageInfo.page = 1;
        this.dataForamt();
      },
      deep: true,
    },
    "pageInfo.goodsType": {
      handler() {
        this.pageInfo.page = 1;
        this.dataForamt();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.el-table .cell {
  overflow: hidden;
  white-space: nowrap;
}

.goods_box {
  background-color: #ffffff;

  .nav-bar {
    padding: 10px 0;
  }

  .commodityTable {
    text-align: center;
  }

  .foot-btn {
    margin-top: 10px;
  }
}
</style>

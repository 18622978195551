<template>
  <div>
    <div class="page_header_title_box">设备同步记录</div>
    <div style="padding: 70px 20px 20px 20px">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">
            <!-- <el-button class="mini_add_btn" @click="exportXLS()">导入同步记录</el-button> -->
          </div>
          <div>
            <el-input
              placeholder="请输入设备号"
              v-model="pageInfo.machineNbr"
              @keyup.enter.native="onSearch()"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click.prevent="onSearch()"
              ></el-button>
            </el-input>
          </div>
        </div>
        <el-table ref="multipleSelect" :data="tableData" v-loading="loading">
          <el-table-column
            label="设备号"
            align="center"
            prop="machineNbr"
          ></el-table-column>
          <el-table-column
            label="店铺名称"
            align="center"
            prop="channelName"
          ></el-table-column>
          <el-table-column
            label="店铺编码"
            align="center"
            prop="channelNbr"
          ></el-table-column>
          <el-table-column label="设备状态" align="center" prop="machineStatus">
            <template slot-scope="scope">
              {{ scope.row.machineStatus == 1 ? "禁用状态" : "开启状态" }}
            </template>
          </el-table-column>
          <el-table-column label="同步状态" align="center" prop="syncStatus">
            <template slot-scope="scope">
              {{ scope.row.syncStatus == 1 ? "同步成功" : "同步失败" }}
            </template>
          </el-table-column>
          <el-table-column
            label="同步时间"
            align="center"
            prop="createTime"
          ></el-table-column>
          <el-table-column
            label="提示信息"
            align="center"
            prop="errorInfo"
          ></el-table-column>
        </el-table>
        <el-pagination
          layout="sizes, prev, pager, next"
          :total="total"
          :current-page="pageInfo.page"
          :page-sizes="[10, 20, 50, 100, 300]"
          @size-change="onSizeCHange"
          @current-change="onCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import url from "@/api/allUrl";
export default {
  name: "synchronizeDeviceRecords",
  data() {
    return {
      pageInfo: { page: 1, size: 10, machineNbr: undefined },
      tableData: [],
      loading: false,
      total: 0,
    };
  },
  activated() {
    this.loading = true;
    this.getdevicerecords();
  },
  methods: {
    exportXLS() {
      var tenantUrl = "";
      tenantUrl =
        process.env.VUE_APP_API_URL + url.exportSynchronizeddevicerecords;
      axios({
        method: "post",
        url: tenantUrl,
        data: {
          machineNbr: "784512456125461325312256132",
          syncStatus: 0,
        },
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
          tenantNo: 100,
          value: 100,
        },
      }).then((res) => {
        const content = res.data;
        let blob = new Blob([content], {
          type: "application/ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        if ("download" in document.createElement("a")) {
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "设备同步记录.xls");
          document.body.appendChild(link);
          link.click();
        } else {
          navigator.msSaveBlob(blob, "设备同步记录.xls");
        }
      });
    },
    //搜索设备列表
    onSearch() {
      this.loading = true;
      this.pageInfo.page = 1;
      this.getdevicerecords();
    },
    getdevicerecords() {
      if (this.pageInfo.machineNbr == "") {
        this.pageInfo.machineNbr = undefined;
      }
      this.$api
        .get(this, url.synchronizeDeviceRecords, this.pageInfo)
        .then((res) => {
          if (res.success) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
          this.loading = false;
        });
    },
    onSizeCHange(val) {
      // 分页方法
      this.pageInfo.size = val;
      this.loading = true;
      this.getdevicerecords();
    },

    onCurrentChange(val) {
      // 分页方法
      this.pageInfo.page = val;
      this.loading = true;
      this.getdevicerecords();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
